/*
    Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
    Unauthorized copying of this file, via any medium is strictly prohibited
    Proprietary and confidential
*/

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './react-auth0-wrapper';
import config from './auth_config.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainApp from './MainApp';

const onRedirectCallback = appState => {
    window.history.replaceState(
        {},
        document.title,
        // appState && appState.targetUrl
        //     ? "http://localhost:3000/"
        //     :
        window.location.origin,
	document.cookie = "isAuthenticated=1;Expires=''"
    );
};

ReactDOM.render(<Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    prompt="login"
>
    <MainApp />
</Auth0Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
