/*
    Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
    Unauthorized copying of this file, via any medium is strictly prohibited
    Proprietary and confidential
*/
import { toast } from 'react-toastify';


export const downloadTxtFile = (searchedText, imagesData, folderName, webOrigin) => {
    imagesData = imagesData.filter(item => item.checked === true);
    if(imagesData.length > 0){
        const element = document.createElement('a');
        var text = '';
        const label = searchedText.length > 0 ? searchedText : '-';

        for (var i = 0; i < imagesData.length; i+=1) {
            var item = imagesData[i];
            console.log(item);
            var images = '';
            for(var j = 0 ; j < item.selectedNames.length ; j+=1 ){
                images += `${webOrigin}/api/image/${item.selectedNames[j].value},`;
            }
            var x = item.position_x === 'undefined' || item.position_x == null ? '-' : item.position_x;
            var y = item.position_y === 'undefined' || item.position_y == null ? '-' : item.position_y;
            var z = item.position_z === 'undefined' || item.position_z == null ? '-' : item.position_z;
            var extra_field = item.extra_field.trim() ? `${item.extra_field},` : '-';
            text += `${x},${y},${z},${item.marker},${item.labels},${images}${extra_field}\n`;
        }

        const file = new Blob([text], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = folderName + label + '.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    } else {
        return toast.error('Please select at least one record.');
    }
};

export const getCookie = (c_name) => {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + '=');
        if (c_start !== -1) {
            c_start = c_start + c_name.length + 1;
            var c_end = document.cookie.indexOf(';', c_start);
            if (c_end === -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return '';
};