import React from 'react';
import IframeComponent from '../components/IframeComponent';
import { ApiConfig } from '../common/ApiConfig';

class ImageAnnotation extends React.Component {

    render() {
    	var src = `${window.location.origin}/pages/viadsf.html?token=${this.props.token}&session=${ApiConfig.SessionId}`;
    	return (
			<div>
		        <IframeComponent src={src} title="This is a unique title" />
		    </div>
	    );
    }
}

export default ImageAnnotation;