/*
    Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
    Unauthorized copying of this file, via any medium is strictly prohibited
    Proprietary and confidential
*/

import React, { useState } from 'react';
import App from './App';

/*NEW*/
import { Router, Route, Switch } from 'react-router-dom';
import history from './utils/history';
/*NEW*/

import './App.css';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import { useAuth0 } from './react-auth0-wrapper';
import ImageAnnotation from './views/ImageAnnotation';
import {getCookie} from './common/GlobalFunctions';


const MainApp = () => {
    const [token, setToken] = useState(null);
    const { isAuthenticated, loginWithRedirect, getTokenSilently } = useAuth0();

    if (isAuthenticated) {
        getTokenSilently().then(function (defs) {
            var checkCookie = getCookie('isAuthenticated');
            if(!checkCookie){
                console.log('logout now!')
                var cookies = document.cookie.split(";");

                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
                window.location.reload();
	    }
            setToken(defs);
        });
    }
    
    return (
        isAuthenticated && token ?
                <Router history={history}>
                    <div id="app" className="d-flex flex-column h-100">
                        <Switch>
                            <Route  path="/imageAnnotation" component={() => <ImageAnnotation token={token} />} />
                            <Route exact path="/" component={() => <App token={token} />} />
                        </Switch>
                    </div>
                </Router>
                :
                <Form className="App-View" style={{ justifyContent: "center", alignItems: "center" }}>
                    <FormGroup row style={{ alignItems: "center" }}>
                         <Label className="App-NdtHeader">NDT Scope</Label>
                    </FormGroup>
                    {
                        isAuthenticated === false ?
                        <FormGroup row style={{ alignItems: "center" }} >
                            <br/>
                            <Col>
                                <Button onClick={() => { loginWithRedirect({}) }}>Sign in</Button>
                            </Col>
                        </FormGroup>
                    : null
                    }
                </Form>

    );



}

export default MainApp
