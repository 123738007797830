/*
    Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
    Unauthorized copying of this file, via any medium is strictly prohibited
    Proprietary and confidential
*/


import React from 'react';
import { useAuth0 } from '../react-auth0-wrapper';

const NavBar = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    return (
        <div >
            {!isAuthenticated &&
                <button
                    onClick={() =>
                        loginWithRedirect({})
                    }
                >Sign in</button>
            }

            {isAuthenticated && <button className="btn btn-danger" onClick={async () => {
                logout();
                loginWithRedirect({});
            }}>Log out</button>}
        </div>
    );
};

export default NavBar;