/*
    Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
    Unauthorized copying of this file, via any medium is strictly prohibited
    Proprietary and confidential
*/

import React from 'react';
import { ApiConfig } from '../common/ApiConfig';
export default class IframeComponent extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
			token:props.token
		};
    };

    render() {
    	var url = window.location.href;
	    var image_id = url.substring(url.lastIndexOf('/') + 1);
	    return (
        	<div>
    			<iframe src={`${this.props.src}&id=${ApiConfig.BaseURL}api/image/${image_id}`}  width={'100%'} height={'1080px'}  className="fullheight"/>
  			</div>
        )
    }
}