/*
	Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
	Unauthorized copying of this file, via any medium is strictly prohibited
	Proprietary and confidential
*/
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {reactLocalStorage} from 'reactjs-localstorage';
import { ToastContainer, toast } from 'react-toastify';
import { ApiConfig } from '../common/ApiConfig';


import {
	Row,
	Col,
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup
} from 'reactstrap';

function imageFormatter(cell, row) {
	const link = `<img src="${row.path}?redirect=false" style="width:auto;height:80px"/>`;
	return link;
}


function viewButtonFormatter(cell, row) {
	return <Button color="success" onClick={()=>{{
		reactLocalStorage.setObject('images_ids', row.path)
	//window.location.href = `/imageAnnotation/${row.folder_id}`}}
	window.location.href = `/pages/viadsf.html?token=${this.props.token}&session=${ApiConfig.SessionId}&id=${row.folder_id}`}}
	}>Edit <i className="fa fa-edit"></i></Button>
}


class ViewImagesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hidden: true,
			danger: false
		};
		console.log(this.props)
	}
	//showing message
	renderShowsTotal(start, to, total) {
		return (
			<p style={ { color: '#2574A9' } }>
				Showing   { start } to { to } Images out of { total } Total Records
			</p>
		);
	}

	handleOpen() {
		console.log(this.refs.table.state.selectedRowKeys)
		if(this.refs.table.state.selectedRowKeys == [] ||  this.refs.table.state.selectedRowKeys == ''){
			return toast.error('Please select atleast one record');
			return false;
		}
		reactLocalStorage.setObject('images_ids', this.refs.table.state.selectedRowKeys)
		//window.location.href = `/imageAnnotation/${this.props.folder_id}`
	        //window.location.href = `/pages/viadsf.html?token=${this.props.token}&session=${ApiConfig.SessionId}&id=${this.props.folder_id}`
	        window.open(`/pages/viadsf.html?token=${this.props.token}&session=${ApiConfig.SessionId}&id=${this.props.folder_id}`,"_blank")
	}

	componentDidMount(){
	}

	/* RENDER DATAGRID TABLE */
	 render() {

	 	reactLocalStorage.setObject('images_ids', [])
	 	const selectRowProp = {
			mode: 'checkbox',
			clickToSelect: true,
			bgColor: 'lightgrey'
		};
		const options = {
			page: 1,
			sortName: 'image',
			sortOrder: 'desc',
			prePage: 'Prev',
			nextPage: 'Next',
			firstPage: 'First',
			lastPage: 'Last' ,
			paginationPosition: 'both' ,
			paginationShowsTotal: this.renderShowsTotal,
			sizePerPageList: []
		};
		return (
			<div className="animated fadeIn">
				<ToastContainer position="top-right" autoClose={5000} style={ {top: '80px'} } />
				<Row>
					<Col xs="12" >
						<Card>
							<CardHeader>View Images </CardHeader>
							<CardBody>
								<FormGroup row style={{ alignItems: "center" }} className="pull-right" >
									<Col className="pull-right">
							        	<Button color="#2574a9" onClick={this.handleOpen.bind(this)} className="btn btn-primary">Open Images</Button>{' '}
							        </Col>
		                        </FormGroup>
								<BootstrapTable data={this.props.imageList} version='4' pagination={ true } selectRow={ selectRowProp }  ref='table' className="table table-striped table-responsive table-hover" options={options} search ref='table'>
									<TableHeaderColumn   dataField='image'   dataSort={ true } width="30%">ID</TableHeaderColumn>
									<TableHeaderColumn  dataField='path' dataFormat={ imageFormatter } dataSort={ true } width="20%">Image</TableHeaderColumn>
									<TableHeaderColumn  hidden isKey dataField='path'  dataSort={ true } width="50%">URL</TableHeaderColumn>
									<TableHeaderColumn  hidden dataField='path' dataFormat={ viewButtonFormatter } dataSort={ true } width="20%">Action</TableHeaderColumn>
									<TableHeaderColumn  dataField='folder_id' dataSort={ true } width="20%">folder_id</TableHeaderColumn>
								</BootstrapTable>
							</CardBody>
						</Card>
					</Col>
				 </Row>
			 </div>
		)
 	 }
}

export default ViewImagesList;
