/*
    Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
    Unauthorized copying of this file, via any medium is strictly prohibited
    Proprietary and confidential
*/

import React from 'react';
import './App.css';
import { Button, Form, FormGroup, Label, Col, } from 'reactstrap';
import DropDownComp from './components/DropDownComp';
import ImageListTable from './components/ImageListTable';
import NavBar from './components/NavBar';
import { ApiConfig } from './common/ApiConfig';
import LoadingOverlay from 'react-loading-overlay';
import { getCookie } from './common/GlobalFunctions';
import Select from 'react-select';
import _ from 'underscore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class App extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loading: true,
            folders: [{ id: '', name: '--- Select Folder ---' }],
            folder_id: '',
            searchText: '',
            searchedText: '',
            imagesData: [],
            showNoItemsText: false,
            folderName: '',
            BaseURL: ApiConfig.BaseURL + ApiConfig.FolderService,
            SessionId: ApiConfig.SessionId,
            imageFileOrigin: ApiConfig.BaseURL,
            label_types: [],
            selected_labels: [],
            imageList:[] // Image Annotation Task
        };
        this.getFolderItems = this.getFolderItems.bind(this);
    }


    componentDidMount() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        if (window.location.origin != null && window.location.origin !== 'undefined' && window.location.origin !== 'http://localhost:3000') {
            //var BaseURL = window.location.origin + ApiConfig.FolderService;
            var BaseURL = ApiConfig.BaseURL + ApiConfig.FolderService;
            var SessionId = getCookie('session-id') !== '' ? getCookie('session-id') : ApiConfig.session;
            this.setState({
                BaseURL,
                SessionId,
                imageFileOrigin: window.location.origin
            });
            this.getProjectFolders(BaseURL, SessionId);
        } else {
            this.getProjectFolders();
        }
    }

    /*GET FOLDERS LIST*/
    getProjectFolders(BaseURL = this.state.BaseURL, SessionId = this.state.SessionId) {
        this.setState({
            loading: true
        });

        fetch(`${ApiConfig.proxyurl}${BaseURL}${ApiConfig.Folders}`, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${this.props.token}`,
                'content-type': 'application/json'
            }),
            body: JSON.stringify({
                session: `${SessionId}`
            })
        }).then(resdata => resdata.json())
            .then(res => {
                if (res.folders.length > 0) {
                    this.setState({
                        folders: [...this.state.folders, ...res.folders],
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                });
                return toast.error('Please check your network connection');
            });
    }

    getItemLabels(item_id) {
        this.setState({
            loading: true,
        });
        var that = this;
        return new Promise(function (resolve, reject) {
            fetch(`${ApiConfig.proxyurl}${ApiConfig.BaseURL}${ApiConfig.LabelService}${ApiConfig.ItemLabels}?session=${that.state.SessionId}`, {
                method: 'post',
                headers: new Headers({
                    'Authorization': `Bearer ${that.props.token}`,
                    'content-type': 'application/json'
                }),
                body: JSON.stringify({
                    item_id: item_id,
                })
            }).then(resdata => resdata.json())
                .then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    /*SEARCH IN FOLDER WITH LABEL*/
    getFolderItems(folder_id, labels = []) {
        this.setState({
            loading: true
        });
        const { BaseURL, SessionId } = this.state;
        fetch(`${ApiConfig.proxyurl}${BaseURL}${ApiConfig.Items}`, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${this.props.token}`,
                'content-type': 'application/json'
            }),
            body: JSON.stringify({
                session: `${SessionId}`,
                folder_id: folder_id,
                labels: _.pluck(this.state.selected_labels, 'label')
            })
        }).then(resdata => resdata.json())
            .then(async res => {
                if (res.code !== null && res.code === 'internal') {
                    this.setState({
                        imagesData: [],
                        showNoItemsText: true,
                        loading: false,
                        searchedText: this.state.searchText
                    });
                } else {
                    var allLabelTypes = this.state.label_types;
                    /*FILTER DATA BY TYPE IMAGE*/
                    var filteredItems = res.items.filter(item => (item.mime === 'image/png' || item.mime === 'image/jpg' || item.mime === 'image/jpeg'));
                    var count = 0;
                    var allImagesData = [];
                    var labelData = {};

                    for (var item of filteredItems) {
                        count += 1;
                        var labels = [];
                        item.labelValues = { 'value': item.name, 'label': item.name };
                        item.labelValues = item.name;
                        item.marker = 'orange';
                        item.checked = false;
                        item.labels = [];
                        item.selectedNames = [];
                        item.extra_field = '';

                        // try {
                        //     /* FETCH ITEM LABELS BY ITEM_ID */
                        //     labelData = await this.getItemLabels(item.id);
                        // } catch (err) {
                        //     labelData = {};
                        // }

                        // /*IF LABLES AVAILABLE FOR PARTICULAR ITEM ID*/
                        if (labelData && labelData.labels && labelData.labels.length > 0) {
                            var label_type_ids = _.pluck(labelData.labels, 'label_type_id');

                            for (var label_type_id of label_type_ids) {
                                var label = _.findWhere(allLabelTypes, { value: label_type_id });
                                if (label) {
                                    labels.push(label);
                                }
                            }
                            item.labels = _.pluck(labels, 'label');
                        }

                        allImagesData.push(item);

                        if (count === filteredItems.length) {
                            var uniqueFolderData = [];
                            /*START IMAGE ANNOTATION TASK*/
                            var allImagesArr = [];
                            /*END IMAGE ANNOTATION TASK*/

                            /* MERGE DATA BY FOLDER ID */
                            var obj = {};
                            _.each(_.groupBy(allImagesData, 'folder_id'),  (folder) => {
                                obj = {};
                                obj = folder[0];
                                obj.labelValues = [];
                                var labelValues = _.uniq(_.flatten(_.pluck(folder, 'name')));
                                _.each(labelValues, function (label) {
                                    obj.labelValues.push({ 'value': label, 'label': label });
                                });
                                obj.name = _.uniq(_.flatten(_.pluck(folder, 'name')));
                                obj.selectedNames = obj.labelValues;
                                obj.thumbnail_id = _.uniq(_.flatten(_.pluck(folder, 'thumbnail_id')));
                                obj.labels = _.uniq(_.flatten(_.pluck(folder, 'labels')));
                                /*START IMAGE ANNOTATION*/
                                allImagesArr.push({image:obj.id,path:`${ApiConfig.BaseURL}/api/image/${obj.id}`,folder_id:this.state.folder_id});
                                /*END IMAGE ANNOTATION*/
                                uniqueFolderData.push(obj);
                            });
                            allImagesData = _.sortBy(uniqueFolderData, 'folder_id');
                            this.setState({
                                loading: false,
                                // imagesData: allImagesData,
                                // showNoItemsText: filteredItems.length === 0 ? true : false,
                                showNoItemsText: allImagesArr.length === 0 ? true : false,
                                searchedText: this.state.searchText,
                                imageList: _.uniq(allImagesArr)
                            });
                            this.forceUpdate();
                        }
                    }

                    if(filteredItems.length <= 0){
                        this.setState({
                            loading: false,
                            // imagesData: allImagesData,
                            showNoItemsText: filteredItems.length === 0 ? true : false,
                            searchedText: this.state.searchText
                        });
                    }
                }
            }
            ).catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                });
                return toast.error('Please check your network connection');
            });
    }

    /*FUNCTION USED TO FETCH LABELS OF FOLDER */
    getFolderLabels(folder_id) {
        this.setState({
            loading: true,
            label_types: [],
            selected_labels: []
        });

        this.setState({ selected_labels: [] });
        const { BaseURL } = this.state;
        fetch(`${ApiConfig.proxyurl}${BaseURL}${ApiConfig.LabelTypes}?session=${this.state.SessionId}`, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${this.props.token}`,
                'content-type': 'application/json'
            }),
            body: JSON.stringify({
                folder_id: folder_id,
            })
        }).then(resdata => resdata.json())
            .then(res => {
                if (res.code !== null && res.code === 'internal') {
                    this.setState({
                        showNoItemsText: true,
                        loading: false,
                        searchedText: this.state.searchText
                    });
                } else {
                    var labelsArr = [];
                    res.label_types.forEach(function (label) {
                        labelsArr.push({ 'value': label.id, 'label': label.name });
                    });
                    this.setState({
                        label_types: labelsArr,
                        loading: false,
                        selected_labels: labelsArr
                    });
                    this.forceUpdate();

                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                });
                return toast.error('Please check your network connection');
            });
    }

    /*SELECTED LABELS*/
    updateSelectedLabels(labelValue) {
        this.setState({ selected_labels: labelValue });
    }

    handleSubmit(e) {
        alert('The value is: ' + this.input.value);
        e.preventDefault();
    }

    render() {

        /*SET HOST LABEL VALUE*/
        var getLocation = function(href) {
        var l = document.createElement('a');
        l.href = href;
        return l;
        };

        var location = getLocation(window.location.href);

        const { folders,  folder_id, searchText, loading, showNoItemsText, searchedText, imageFileOrigin } = this.state;
        /* REMOVED VARIABLE imagesData,*/

        return (
            <LoadingOverlay active={loading} spinner >
                <Form className="App-View" onKeyPress={e => {
                    if (e.which === 13)
                        e.preventDefault()
                    }
                }>
                    <ToastContainer position="top-right" autoClose={5000} style={ {top: '80px'} } />
                    <div className="row"style={{ marginTop:'10px'}}>
                        <div className="col-sm-6">
                            <Label><b>HOST: </b>{location.hostname}</Label>
                        </div>
                    </div>

                    {/*HEADER*/}
                    <FormGroup row style={{ alignItems: "center", justifyContent: "space-between" }}>
                        <Label className="App-NdtHeader" style={{ paddingLeft: "10px"}}>NDT Scope</Label>
                        <NavBar />
                    </FormGroup>

                    {/*FOLDER DROPDOWN*/}
                    <DropDownComp title="Select Project Folder" options={folders}
                        onOptionSelect={index => {
                            this.setState({
                                folder_id: folders[index].id,
                                folderName: folders[index].name,
                                imagesData: [],
                                searchText: "",
                                showNoItemsText: false,
                                label_types: [],
                                selected_labels: null,
                                imageList:[]
                            })

                            this.selectedChips.setState({ value: [] })
                            this.forceUpdate();
                            if (folders[index].id) {
                                // this.getFolderLabels(folders[index].id);
                                this.getFolderItems(folders[index].id, []);
                            }
                        }
                        } />

                    {/*LABEL DROPDOWN*/}
                    <FormGroup row>
                        <Col sm={12} lg={12} style={{display:'none'}}>
                            <Label>Select Label</Label>
                        </Col>
                        <Col sm={5}  style={{display:'none'}}>
                            <Select
                                ref={ref => this.selectedChips = ref}
                                isMulti
                                isClearable={true}
                                name="form-field-name2"
                                defaultValue={[]}
                                values={this.state.selected_labels}
                                options={this.state.label_types}
                                onChange={this.updateSelectedLabels.bind(this)}>
                            </Select>
                        </Col>
                        <Col  style={{display:'none'}}>
                            <Button className="btn btn-info" onClick={() => {
                                if (folder_id !== "") {
                                    var labels = [];
                                    if (searchText.trim().length > 0)
                                        labels.push(searchText);
                                    this.getFolderItems(folder_id, labels);
                                } else {
                                    return toast.error('Please Select Folder.');
                                }
                            }} title="Search">Search</Button>
                        </Col>
                    </FormGroup>

                   {/* {imagesData.length > 0 ?
                        <FormGroup row>
                            <Col sm={12} lg={9}>
                                <Button type="button" className="btn btn-info" title="Generate" onClick={() => { downloadTxtFile(this.state.searchedText, this.state.imagesData, this.state.folderName, imageFileOrigin) }}>Generate File</Button>
                            </Col>
                        </FormGroup>
                        : null
                    }
                */}


                    {this.state.imageList.length > 0 ?
                        <FormGroup row>
                            <Col sm={12} lg={12}>
                                <ImageListTable imageList={this.state.imageList} folder_id={this.state.folder_id} token={this.props.token}/>
                            </Col>
                        </FormGroup>
                        : null
                    }


                    {showNoItemsText ?
                        <Label>No items available for this search</Label>
                        : null
                    }

                </Form >
            </LoadingOverlay>
        );
    }
}

export default App;
