/*
    Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
    Unauthorized copying of this file, via any medium is strictly prohibited
    Proprietary and confidential
*/

import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import './../App.css';

const DropDownComp = (props) => {
    return (
        <FormGroup style={{ width: "50%" }}>
            <Label>{props.title}</Label>
            <Input type="select" id="exampleSelect" bsSize="sm" sm={3}
                onChange={event => {
                    props.onOptionSelect(event.target.value);
                }}>
                {
                    props.options.map((item, index) =>
                        <option value={index} key={item.id}>{item.name}</option>
                    )
                }
            </Input>
        </FormGroup>
    );
}

export default DropDownComp
