// Copyright (C) 2018 Deep Skills Inc., - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

export const ApiConfig = {
    'proxyurl': 'https://cors-anywhere.herokuapp.com/',
    // BaseURL: 'http://119.9.104.140:8083',
    'BaseURL': 'https://dev004.cognivea.com',
    // 'BaseURL': 'http://3.19.233.150:8085/',
    'FolderService': '/rpc.FolderService/',
    'LabelService': '/rpc.LabelService/',
    'Folders': 'Roots',
    'Items': 'SearchItems',
    'LabelTypes':'LabelTypes',
    'SessionId': 'dadc2a6f-5af1-45df-bc3f-72297ed3b679',
    'ItemLabels':'Get',
    'Token':'abcd'
};
